head, body {
background: #570026;
font-family: "Montserrat", sans-serif;
color: #FFF;
}

a{
  cursor: pointer;
  text-decoration: none;
  color: white;
}

ul {
  padding-left: 1rem;
}

footer nav a:hover {
  opacity: 0.8;
}

*:not(:hover){
  transition: 1.2s;
}

/* Snow Animation */

.snowflake {
  color: #fff;
  font-size: 0.75em;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:15s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:15s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}

/* Snow Animation */

.container {
  overflow: hidden;
}

header > .container{
  overflow: visible;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.nav{
  justify-content: space-around;
  border-radius: 50px;
  background: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  max-width: 1056px;
  min-width: 50vw;
  box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
}

.nav-link{
  cursor: pointer;
}

.nav-link:hover{
  opacity: 0.8;
}

.dropdown-menu{
  background-color: #000;
  z-index: 99999;
  opacity: 1;
}

.dropdown-item{
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}


.logo-nav {
  width: 72.5px;
}

.hero-promo{
  align-items: center;
  height: 200px;
  background-image: url('./assets/images/img/hero-promo-bg1.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(5px);
}

.hero-promo-slide{
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.promo-h1{
  font-family: "Caveat", sans-serif;
  font-weight: 500;
}

.promo-h4{
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.promo-h3{
  font-family: "Caveat", sans-serif;
  font-weight: 500;
}

/* .mothers-day {
  align-items: end;
  height: 200px;
  background: rgb(224,200,176);
  background: linear-gradient(90deg, rgba(224,200,176,1) 0%, rgba(166,140,140,1) 100%);
} */

.mothers-day {
  align-items: end;
  height: 200px;
  background: url("../src/assets/images/img/swiper-promo/BgNySwiper.png"), linear-gradient(to right, #D4DDFF, #BBA5DF);
  background-repeat: no-repeat;
  background-size: contain; /* Уменьшается пропорционально блоку */
  background-position: center; /* Центрирует изображение в блоке */
}

.Mothers-image{
  max-width: 180px;
  max-height: 180px;
}

.mother-hero-text{
  min-height: 180px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mother-title{
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
}

.mother-text {
  font-size: 18px !important;
  max-width: 310px;
  font-style: "Montserrat", sans-serif;
}

.mother-order{
  height: 30px;
  margin: 0 !important;
  background-color: #000;
  border-radius: 15px 15px 0 0;
  width: 450px;
  padding: 3px 0 5px 0;
}

.mother-order p {
  font-family: "Montserrat", sans-serif;
  margin: 0 !important;
}

.aboutus-text{
  max-width: 600px;
}

.advantage {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.advantage-text{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;

  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
}

.description{
  color: #ffffff;
  background: url('./assets/images/img/Descriptions2.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
}

.lead{
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.categories .row{
  /* gap: 20px; */
  justify-content: center;
}

.categories a {
  padding: 0;
  margin: 0;
  width: 257px;
}

.category {
  position: relative;
  overflow: hidden;
  width: 257px;
  height: 256px;
  border-radius: 15px;
  background-image: radial-gradient(circle 150px, #D85CAE, rgb(0, 0, 0));
}

.category > h3 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.category > img {
  width: 257px;
  height: 256px;
}

.category > img:hover {
  scale: 1.1;
  transition: all 1.2s ease;
}

.category:hover {
  transition: all 1.2s ease;
  box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
}

.discount-text{
  font-size: 28px;
  font-family: "Caveat", sans-serif;
  font-weight: 500;
}

/* marquee-wrapper */

.marquee-wrapper {
  max-width: 100%;
  margin: 0;
  background-color: #000;
}

.marquee-items-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}

.marquee-items-wrapper:before,
.marquee-items-wrapper:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.marquee-items {
  flex-shrink: 0;
  display: flex;
  gap: 50px;
  justify-content: space-around;
  min-width: 100%;
}

.marquee-item {
  background: #000000;
  flex: 0 0 auto;
  width: auto;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 10px 15px;
  margin: 10px 0;
  transition: all 0.1s ease-in-out;
}

.marquee-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.marquee-items {
  animation: scroll 20s linear infinite;
}

.marquee-items-wrapper:hover .marquee-items {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

.btn{
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background-color: #000000;
}

.btn:hover{
  opacity: 0.8;
}

/* Стили для модального окна */
.modal {
  display: none; /* Скрываем модальное окно по умолчанию */
  position: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
  z-index: 100000;
}

.modal-content {
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.contact-options {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #fff;
  background-color: #57002691;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
}

.contact-link:hover {
  background-color: #570026;
}

.contact-link p{
  margin: 0;
  padding: 0;
}

.contact-link img{
  right: 0;
  height: 25px;
  width: 25px;
}

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}


.sm-icon {
  width: 40px;
  height: 40px;
}

.sm-icon:hover{
  opacity: 0.8;
}

.mini-badge__org-name {
  display: none;
}

footer p {
  margin-bottom: 0.7rem !important;
}

.copyright{
  width: -webkit-fill-available;
} 

.burger {
  margin: 5px 35px;
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  position: relative;
}

.burger span {
  display: block;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
}

/* Верхняя линия при активации (превращается в крестик) */
.burger.active span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

/* Средняя линия исчезает */
.burger.active span:nth-child(2) {
  opacity: 0;
}

/* Нижняя линия при активации (превращается в крестик) */
.burger.active span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* PRODUCT-LIST */
.product-list{
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

/* PRODUCT-CARD */

.product-card {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  background-color: #ECECEC;
  border: #ffffff 0.5px solid;
  border-radius: 15px;
  padding: 0;
  max-width: 260px;
  height: 343px;
  width: 100%;
}

.product-card:hover{
  box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.75);
}

.product-image {
  max-width: 260px;
  max-height: 260px;
  width: 260px;
  height: 260px;
  border-radius: 8px;
}

.product-details{
  max-width: 260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px 0 10px;
  color: #000;
}

.product-details h3 {
  text-align: center;
  font-size: 14px;
  margin: 10px 0;
}

.product-card-bg-price{
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  width: 83px;
  height: 27px;
  background-color: #000;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
}

.product-card-price {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}

.price-crossed {
  text-decoration: line-through;
  color: #FFF;
}

.product-card-bg-price-discount{
  z-index: 1;
  margin-right: -15px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  width: 83px;
  height: 27px;
  background-color: #FF6004;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
}

.product-card-price-discount{
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}

.unavailable-bg{
  width: 150px;
  height: 27px;
  background-color: #000;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.unavailable-text{
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
}

.unavailable-bg-page{
  width: 180px;
  height: 37px;
}

/* PRODUCT-PAGE */
.product-page{
  width: 100%;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
}

.product-image-page{
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.product-page-inner{
  justify-content: center;
  gap: 20px;
}

.product-page-text-inner{
  display: grid;
  grid-template-rows: auto auto 50px 100px 40px 40px;
  width: 100%;
  max-width: 700px;
}

.product-page-text-inner .product-card-bg-price{
  width: 93px;
  font-size: 16px;
  margin-bottom: 10px;
}

.product-page-text-inner .product-card-bg-price-discount{
  width: 93px;
  font-size: 16px;
  margin-bottom: 10px;
}

.product-page-text-inner .product-card-price-discount .product-card-price{
  font-size: 16px;
}

/* SWIPER */
.swiper-wrapper{
  max-width: 500px;
}

.swiper-container-aboutus{
  width: 100%;
  height: auto;
  max-width: 500px;
}

.swiper-pagination-bullet {
  background: #ffffff !important;
}

.swiper-pagination-bullet-active {
  background: #000000 !important;
}
/* PRODUCT-MODAL */
.modal-overlay-card {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content-card {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  padding: 0;
  border-radius: 15px;
  max-width: 900px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content-left{
  flex: 1 1 500px;
  flex-direction: column;
}

.modal-content-right{
  flex: 1 2 400px;
  padding: 15px;
  height: 500px;
  max-height: 500px;
}

.modal-content-right h2{
  font-size: 24px;
  font-weight: 400;
}

.modal-content-right p{
  font-size: 14px;
}

.modal-content-right .btn{
  align-self: self-end;
}

.modal-image-card {
  width: 500px;
  max-width: 500px;
  height: 100%;
  max-height: 500px;
  border-radius: 15px 0 0 15px;
}


.modal-characteristics {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.modal-characteristics li {
  margin-bottom: 5px;
  font-weight: 300;
}

.description-section {
  margin-top: 20px;
  font-size: 16px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-item:nth-last-child(1) {
  margin-bottom: 2rem;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.faq-answer {
  margin-top: 10px;
}

.scroll-to-top {
  position: fixed; 
  z-index: 10000;
  bottom: 20px;
  right: 20px;
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0.6;
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  opacity: 1;
}

@media (max-width: 991px){
  .Mothers-image-bouquet{
    display: none;
  }

  .mothers-day {
    align-items: end;
    background: url("../src/assets/images/img/swiper-promo/BgNySwiper991px.png"), linear-gradient(to right, #D4DDFF, #BBA5DF);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }
}


@media (max-width: 768px) {

  .snowflake {
    color: #fff;
    font-size: 0.5em;
    text-shadow: 0 0 1px #000;
  }

  .burger {
      display: flex;
  }
  
  .nav {
      display: none;
      flex-direction: column;
      background-color: #000;
      padding: 10px;
      border-radius: 10px;
  }

  .nav.active {
      display: flex;
  }

  .nav-item {
      margin: 0;
  }

  .dropdown-menu {
    text-align: center;
    position: static; /* Меню становится статическим на мобильных */
    width: 100%; /* Занимает всю ширину */
  }

  .dropdown-toggle {
    text-align: center;
    display: block; /* Делаем его блочным элементом */
    width: 100%; /* Занимает всю ширину */
  }

  .header {
      position: fixed;
      top: 0;
      background-color: #000000;
      width: 100%;
      z-index: 1000;
      border-radius: 0 0 10px 10px;
  }

  .bouquet-page{
    margin-top: 7rem !important;
  }

  .mother-hero-text{
    width: 100%;
    justify-content: space-between;
  }

  .Mothers-image{
    width: 160px;
    height: 160px;
  }

  .mother-title{
    font-size: 24px;
  }

  .mother-text{
    font-weight: 300;
    line-height: 1;
    font-size: 14px !important;
  }

  .mother-order{
    width: 150px;
    padding: 5px 0 5px 0;
  }

  .mother-order p{
    font-size: 12px;
  }

  .swiper-container-aboutus{
    max-width: 380px;
  }

  .marquee-item:hover {
    transform: none;
    box-shadow: none;
  }
  
  .marquee-items-wrapper:hover .marquee-items {
    animation-play-state: running;
  }

  footer .row {
    gap: 20px;
  }

  .modal h2{
      font-size: 20px;
  }

  .close {
      top: 15px;
  }

  .hero{
      margin-top: 60px;
  }

  .aboutus{
    margin-top: 80px !important;
  }

  .category {
      width: 227px;
      height: 236px;
      box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  }

  .bouquet {
      width: 220px;
      height: 186px;
  }

  .toys {
      width: 226px;
      height: 180px;
  }

  .artificial-flowers{
      width: 256px;
      height: 155px;
  }

  /* PRODUCT */
  .product-list{
    margin-top: 60px;
  }

  /* PRODUCT-PAGE */
  .product-page{
    margin-top: 70px;
  }

  .delivery {
    margin-top: 80px !important;
  }

  .care-guide{
    margin-top: 80px !important;
  }

  .faq-page{
    margin-top: 80px !important;
  }

  .product-page-text-inner {
    grid-template-rows: auto auto auto 100px 40px 40px;
  }
}